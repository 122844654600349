import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const DeleteConfirmationModal = ({
  open = false,
  onClose,
  onDelete,
  deleteMsg,
}) => {
  return (
    <Dialog
      sx={{
        width: "100%",
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <Box sx={{ p: 2, width: "500px" }}>
        <DialogTitle>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              color: "#003554",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant={"p"}>Delete Confirmation</Typography>
            <IconButton
              sx={{
                color: "#ffff",
                position: "absolute",
                top: "15px",
                right: "15px",
                padding: "3px",
                backgroundColor: "#D9D9D9",
                "&:hover": {
                  backgroundColor: "#D9D9D9",
                },
              }}
              onClick={onClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 2, textAlign: "center", color: "#858585" }}>
          <Typography variant="h6">{deleteMsg}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", p: 2 }}>
          <Button
            sx={{
              color: "#003554",
              borderColor: "#003554",
            }}
            onClick={onClose}
            variant="outlined">
            Cancel
          </Button>
          <Button
            sx={{
              color: "#ffff",
              backgroundColor: "#003554",
            }}
            onClick={onDelete}
            variant="contained"
            color="primary">
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
