import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { InterviewRoutes, privateRoutes, publicRoutes } from "../Routes";
import PublicRoute from "../middleware/PublicRoute";
import PrivateRoute from "../middleware/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../Redux/user/actions";
import { getToken } from "../service/JWT";
import ProgressModal from "../components/MODELS/ProgressModal";
import SpinnerModal from "../components/MODELS/SpinnerModal";
import DeleteConfirmationModal from "../components/MODELS/DeleteConfirmationModal";
import { deleteJobRequest } from "../service/Api/jobs";
import { toast } from "react-toastify";
import Spinner from "../components/UI/Spinner";
import InterviewRoute from "../middleware/InterviewRoute";
import { deleteExperienceQuestion } from "../service/Api/experience";
import { requestError } from "../utils/reusableFun";

function Pages() {
  let dispatch = useDispatch();
  const {
    progress,
    isProgressModal,
    isSpinner,
    isDelete,
    deleteMsg,
    deleteType,
    deleteId,
  } = useSelector((state) => state?.modal);
  let { isAuth, loading } = useSelector((state) => state.user);
  const token = getToken();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch, token]);

  const handleCloseProgressModal = () => {};
  const handleCloseSpinnerModal = () => {};
  const handleCloseDelete = () => {
    dispatch({
      type: "delete_modal",
      payload: {
        isDelete: false,
        deleteMsg: "",
        deleteType: null,
        deleteId: null,
      },
    });
  };
  const handleDelete = () => {
    switch (deleteType) {
      case "delete_job":
        deleteJobRequest(deleteId)
          .then((res) => {
            if (res?.status === 200) {
              dispatch({
                type: "delete_modal",
                payload: {
                  isDelete: false,
                  deleteMsg: "",
                  deleteType: "delete_job",
                  deleteId: deleteId,
                },
              });
              toast.success("Job Deleted Successfully");
            }
          })
          .catch(() => {});
        break;
      case "delete_question":
        deleteExperienceQuestion({ question_id: deleteId })
          .then((res) => {
            if (res?.status === 200) {
              dispatch({
                type: "delete_modal",
                payload: {
                  isDelete: false,
                  deleteMsg: "",
                  deleteType: "delete_question",
                  deleteId: deleteId,
                },
              });
              toast.success("Question Deleted Successfully");
            }
          })
          .catch((error) => {
            requestError(error);
          });
        break;
      default:
        break;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <ProgressModal
        open={isProgressModal}
        progress={progress}
        handleClose={handleCloseProgressModal}
      />
      <SpinnerModal open={isSpinner} handleClose={handleCloseSpinnerModal} />

      <DeleteConfirmationModal
        open={isDelete}
        onClose={handleCloseDelete}
        deleteMsg={deleteMsg}
        onDelete={handleDelete}
      />
      <Routes>
        {/* Public Routes */}
        {publicRoutes.map((ele, index) => {
          return (
            <Route
              key={`public-route-${index}`}
              exact
              path={ele.path}
              element={
                <PublicRoute isAuthenticated={isAuth} title={ele?.title}>
                  {ele?.component}
                </PublicRoute>
              }
            />
          );
        })}

        {/* Private Routes */}
        {privateRoutes.map((ele, index) => {
          return (
            <Route
              key={`private-route-${index}`}
              exact
              path={ele.path}
              element={
                <PrivateRoute isAuthenticated={isAuth} title={ele?.title}>
                  {ele?.component}
                </PrivateRoute>
              }
            />
          );
        })}

        {InterviewRoutes.map((ele, index) => {
          return (
            <Route
              key={`interview-route-${index}`}
              exact
              path={ele.path}
              element={
                <InterviewRoute isAuthenticated={isAuth} title={ele?.title}>
                  {ele?.component}
                </InterviewRoute>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default Pages;
