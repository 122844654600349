import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@mui/material";

const SpinnerModal = ({ open, handleClose, progress }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Fade in={open}>
        <CircularProgress />
      </Fade>
    </Modal>
  );
};

export default SpinnerModal;
