import { toast } from "react-toastify";

export function requestError(error, setErrors = null) {
  if (error?.response?.status === 400) {
    if (error?.response?.data?.noField) {
      toast.error(error?.response?.data?.noField);
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      setErrors && setErrors(error?.response?.data?.errors);
    }
  } else if (error?.response?.status === 404) {
    toast.error(error?.response?.data?.noField);
  } else {
    toast.error(error?.response?.data?.message);
  }
}

export function makeQueryStringToObject(searchString) {
  let arrayParam = searchString?.replace("?", "")?.split("&");
  let obj = {};
  arrayParam?.forEach((elem) => {
    let keyArr = elem?.split("=");
    obj[keyArr[0]] = keyArr[1];
  });
  return obj;
}
