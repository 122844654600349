import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import Spinner from "./components/UI/Spinner";
import { Provider} from "react-redux";
import store from "./Redux/store";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pages from "./pages";
function App() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="container">
            <Spinner />
          </div>
        }>
        <Router>
          <ToastContainer />
          <Pages />
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
