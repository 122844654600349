import { lazy } from "react";
const UpdateJob = lazy(() => import("../pages/private/UpdateJob"));
const CreateJob = lazy(() => import("../pages/private/CreateJob"));
const Dashboard = lazy(() => import("../pages/private/Dashboard"));
const JobsListing = lazy(() => import("../pages/private/JobListing"));
const Settings = lazy(() => import("../pages/private/Settings"));
const CandidateListing = lazy(() =>
  import("../pages/private/CandidateListing")
);

const Register = lazy(() => import("../pages/public/register"));
const VerifyEmail = lazy(() => import("../pages/public/verify-email"));
const Login = lazy(() => import("../pages/public/login"));
const ForgotPassword = lazy(() => import("../pages/public/forget-password"));
const CreateQuestion = lazy(() => import("../pages/private/CreateQuestion"));
const CandidateForm = lazy(() => import("../pages/public/CandidateForm"));
const NotFound = lazy(() => import("../pages/public/404"));
const Interview = lazy(() => import("../pages/public/Interview"));
const ResetPasssword = lazy(() => import("../pages/public/reset-password"));
const CandidateDetails = lazy(() =>
  import("../pages/private/CandidateDetails")
);

const publicRoutes = [
  { path: "/", component: <Login />, title: "WebSpero - Home" },
  { path: "/register", component: <Register />, title: "WebSpero - Register" },
  { path: "/verify-email/:token", component: <VerifyEmail /> },
  {
    path: "/forget-password",
    component: <ForgotPassword />,
    title: "WebSpero - Forget Password",
  },
  {
    path: "/reset-password/:token",
    component: <ResetPasssword />,
    title: "WebSpero - Reset Password",
  },
];

const privateRoutes = [
  { path: "/*", component: <NotFound />, title: "WebSpero - Not Found" },
  {
    path: "/dashboard",
    component: <Dashboard />,
    title: "WebSpero - Dashboard",
  },
  {
    path: "/create-job",
    component: <CreateJob />,
    title: "WebSpero - Create Job",
  },
  {
    path: "/update-job/:jobId",
    component: <UpdateJob />,
    title: "WebSpero - Update Job",
  },
  { path: "/jobs", component: <JobsListing />, title: "WebSpero - Jobs" },
  {
    path: "/create-question",
    component: <CreateQuestion />,
    title: "WebSpero - Create Question",
  },
  {
    path: "/candidates/:job_id",
    component: <CandidateListing />,
    title: "WebSpero - Candidates",
  },
  {
    path: "/candidate/:candidate_id",
    component: <CandidateDetails />,
    title: "WebSpero - Candidate Details",
  },
  { path: "/settings", component: <Settings />, title: "WebSpero - Settings" },
  // { path: "/candidates/:job_id", component: "" },
  // { path: "/candidate-details/:candidate_id", component: "" },
];

const InterviewRoutes = [
  {
    path: "/job/:jobHash",
    component: <CandidateForm />,
    title: "WebSpero - Candidate Form",
  },
  {
    path: "/interview/:interview_id",
    component: <Interview />,
    title: "WebSpero - Interview",
  },
];

export { privateRoutes, publicRoutes, InterviewRoutes };
