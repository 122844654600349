import { Navigate } from "react-router-dom";
import Navbar from "../components/UI/Navbar";
import Sidebar from "../components/UI/Sidebar";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", // Typical Material-UI elevation shadow
          backgroundColor: "#ffff", // Assuming your theme has this as a light color
          color: "#17335E", // Text color inside the tooltip
          fontSize: "1rem", // Custom font size
          borderRadius: "5px", // Rounded corners for the tooltip
          padding: "10px 15px", // Custom padding for the tooltip
        },
        arrow: {
          color: "#ffff", // Arrow color should match the tooltip background
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#ffff",
            color: "#17335E",
            borderColor: "#17335E",
            "&:hover": {
              backgroundColor: "#ffff",
            },
          },
          "&.Mui-disabled": {
            opacity: "inherit",
            border: "1px solid #DFE3E8",
            backgroundColor: "#ffff",
            color: "#DFE3E8",
          },
          "&:hover": {
            backgroundColor: "#ffff",
            borderColor: "#17335E",
          },
        },
        text: {
          fontWeight: "bold",
        },
        outlined: {
          border: "1px solid #DFE3E8",
          backgroundColor: "#ffff",
        },

        previousNext: {
          backgroundColor: "#17335E",
          color: "#ffff",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.paperDropdown": {
            backgroundColor: "#ffff",
            borderRadius: 3,
            color: "#17335E",
            border: 0,
            boxShadow:
              "0px 0px 0px rgba(0,0,0,0.10),0px 0px 10px rgba(0,0,0,0.10)",
            "&:after": {
              border: 0,
            },
            "&:before": {
              border: 0,
              borderBottom: "0 !important",
            },

            "&:hover": {
              backgroundColor: "#ffff",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
              "&:hover": {
                border: 0,
              },
            },

            "& .MuiFormLabel-root": {
              color: "#17335E",
            },

            "& .MuiSelect-select": {
              "&:focus": {
                background: "none",
                backgroundColor: "#ffff",
              },
            },

            "& .MuiSvgIcon-root": {
              fill: "#17335E",
              fontSize: "1.8rem",
              color: "#17335E",
            },
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.paperDropdown": {
            backgroundColor: "#ffff",
            borderRadius: 3,
            boxShadow:
              "0px 0px 0px rgba(0,0,0,0.10),0px 0px 10px rgba(0,0,0,0.10)",
            "& .MuiFormLabel-root": {
              color: "#17335E",
            },

            "& .MuiInputBase-root": {
              color: "#17335E",
              stroke: "#17335E",
              strokeWidth: "1px",
              background: "none",
              borderRadius: "0px",
              border: 0,
              "&:after": {
                border: 0,
              },
              "&:before": {
                border: 0,
              },

              "& .MuiSelect-select": {
                "&:focus": {
                  background: "none",
                  backgroundColor: "#ffff",
                },
              },
            },
          },
        },
      },
    },

    MuiPaper: {
      root: {
        "& .quill .ql-toolbar.ql-snow": {
          border: "none !important",
          boxShadow: "none !important",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#17335E",
    },
    secondary: {
      main: "#19857b",
    },
  },
});
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 1000000,
    height: "70px",
    boxShadow: "none",
    background: "#ffff",
  },
  content: {
    flexGrow: 1,
    padding: "28px",
  },
}));

// PrivateRoute component for routes accessible only by authenticated users
const PrivateRoute = ({ children, isAuthenticated, title, ...rest }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}>
          {isSidebarOpen && (
            <Box sx={{ width: "15%" }}>
              <Sidebar
                isOpen={isSidebarOpen}
                onClose={() => setSidebarOpen(false)}
              />
            </Box>
          )}

          <Box sx={{ width: isSidebarOpen ? "83%" : "100%" }}>
            <Grid container spacing={0}>
              <Grid item md={12} xs={12} sm={12}>
                <Navbar
                  onSidebarToggle={handleSidebarToggle}
                  classes={classes}
                  isAuthenticated={isAuthenticated}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                <Box
                  sx={{
                    backgroundColor: "#F6FCFF",
                    height: "calc(100vh - 185px)",
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                  borderRadius={3}
                  padding={5}>
                  {children}
                </Box>
              </Grid>

              <Grid item md={12} xs={12} sm={12}>
                <Box sx={{ height: "35px" }}></Box>
              </Grid>
            </Grid>
          </Box>

          {isSidebarOpen && (
            <Box sx={{ width: "2%" }}>
              <Box
                sx={{
                  height: "100%",
                  borderRadius: "0",
                  width: "100%",
                  minWidth: "100%",
                }}></Box>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PrivateRoute;
