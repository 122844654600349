import { getToken } from "../../JWT";
import { privateAxios } from "../axiosInstance";

const createHeaders = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
});

export const fetchExperienceRequest = async () => {
  let res = await privateAxios.get("/fetch-experience", createHeaders());
  return res;
};

export const addExperience = async (experienceData) => {
  const res = await privateAxios.post(
    "/add-experience",
    experienceData,
    createHeaders()
  );
  return res;
};

export const updateExperience = async (id, experienceData) => {
  const res = await privateAxios.post(
    `/update-experience?experience_id=${id}`,
    experienceData,
    createHeaders()
  );
  return res;
};

export const deleteExperience = async (experienceID) => {
  const res = await privateAxios.delete(`/delete-experience`, {
    data: experienceID,
    headers: createHeaders(),
  });
  return res;
};

export const fetchExperienceQuestionsRequest = async ({
  experience_id,
  page = 1,
  limit = 10,
}) => {
  let res = await privateAxios.get(
    `/fetch-experience-questions?experience_id=${experience_id}&page=${page}&limit=${limit}`,
    createHeaders()
  );
  return res;
};

export const createExperienceQuestion = async (data) => {
  let res = await privateAxios.post(
    `/create-experience-question`,
    data,
    createHeaders()
  );
  return res;
};

export const updateExperienceQuestion = async (data) => {
  let res = await privateAxios.post(
    `/update-experience-question`,
    data,
    createHeaders()
  );
  return res;
};

export const deleteExperienceQuestion = async ({ question_id }) => {
  let res = await privateAxios.get(
    `/delete-experience-question?question_id=${question_id}`,
    createHeaders()
  );
  return res;
};
