export const API_URL =
  process.env.REACT_APP_API_URL || "http://192.168.2.51:5001/api";
export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL || "http://192.168.2.51:5001";
export const BACKEND_URL =
  process.env.REACT_APP_SOCKET_URL || "http://192.168.2.51:5001";
export const FRONTEND_URL =
  process.env.REACT_APP_FRONTEND_URL || "http://192.168.2.51:5001";

export const BACKEND_MEDIA = process.env.REACT_APP_BACKEND_MEDIA;

export const MIN_CHARACTER_LENGTH = process.env.REACT_APP_DESCRIPTION_MIN_CHARACTER;
export const MAX_CHARACTER_LENGTH = process.env.REACT_APP_DESCRIPTION_MAX_CHARACTER;
