const initialState = {
  isAuth: null,
  token: null,
};

export default function authReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
}
