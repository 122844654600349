// Sidebar.js

import React from "react";
import { List, ListItem, ListItemText, Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as JobIcon } from "../../assets/icons/jobs.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/questions.svg";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../service/JWT";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const Sidebar = ({ isOpen, onClose }) => {
  const SUPERUSER = "admin";
  const { user } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const Logout = () => {
    try {
      removeToken();
      dispatch({ type: "logout" });
    } catch (error) {
      console.error("Error inside Logout function", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        borderRadius: "0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flexGrow: 0,
          textAlign: "center",
          paddingTop: 2,
        }}
      >
        <img src="/companyLogo.png" alt="Logo" />
      </Box>

      <Box
        sx={{
          flexGrow: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#858585",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
          paddingTop={3}
        >
          <List>
            <ListItem
              sx={{
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#DDEDF7",
                  // "& svg path": {
                  //   fill: "#17335E", // Change to your desired color on hover
                  // },
                },
              }}
              component={RouterLink}
              to="/dashboard"
            >
              <Box paddingRight={2}>
                <DashboardIcon />
              </Box>
              <ListItemText
                sx={{ color: "#858585", marginBottom: "4px" }}
                primary="Dashboard"
              />
            </ListItem>
            <ListItem
              sx={{
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#DDEDF7",
                },
              }}
              component={RouterLink}
              to="/jobs"
            >
              <Box paddingRight={2}>
                <JobIcon />
              </Box>
              <ListItemText
                sx={{ color: "#858585", marginBottom: "4px" }}
                primary="Jobs"
              />
            </ListItem>
            <ListItem
              sx={{
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#DDEDF7",
                },
              }}
              component={RouterLink}
              to="/create-question"
            >
              <Box paddingRight={2}>
                <QuestionIcon />
              </Box>
              <ListItemText
                sx={{ color: "#858585", marginBottom: "4px" }}
                primary="Org Question"
              />
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            justifyContent: "end",
            display: "flex",
            flexDirection: "column",
            color: "#17335E",
            fontWeight: "500",
            alignItems: "center",
            height: "70%",
          }}
        >
          {user.role === SUPERUSER && (
            <Box
              component={RouterLink}
              to="/settings"
              sx={{
                width: '100%',
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                gap: "3px",
                ":hover": {
                  cursor: "pointer",
                  textDecoration: "none",
                },
                paddingBottom: 3,
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <SettingsIcon />
              <Typography variant={"p"}>Settings</Typography>
            </Box>
          )}
          <Box
            onClick={(e) => {
              Logout();
            }}
            sx={{
              width: '100%',
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              ":hover": {
                cursor: "pointer",
              },
              paddingBottom: 3,
            }}
          >
            <LogoutIcon />
            <Typography variant={"p"}>Logout</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
